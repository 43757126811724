@import '../../scss/utilities';

$ue-headshot-lg: 115px;
$ue-headshot-md: 105px;
$ue-headshot-sm: 60px;

.headshot {
  padding-bottom: $grid-gutter-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &_lgCard {
    padding-top: $ue-headshot-lg*0.5;
  }
  &_mdCard {
    padding-top: $ue-headshot-md*0.5;
  }
  &_smCard {
    padding-top: $ue-headshot-sm*0.5;
  }
}

.headshot__image {
  border-radius: 50%;
  &_lg {
    width: $ue-headshot-lg;
    height: $ue-headshot-lg;
    &Card {
      position: absolute;
      top: -$ue-headshot-lg*0.5;
    }
  }
  &_md {
    width: $ue-headshot-md;
    height: $ue-headshot-md;
    &Card {
      position: absolute;
      top: -$ue-headshot-md*0.5;
    }
  }
  &_sm {
    width: $ue-headshot-sm;
    height: $ue-headshot-sm;
    &Card {
      position: absolute;
      top: -$ue-headshot-sm*0.5;
    }
  }
}

.headshot__featureInfo {
  text-align: center;
  &_card {
    padding: 0 15px;
  }
  &_withSpacing {
    min-height: 10rem;
  }
}

.headshot__featureLink {
  font-size: $ue-font-size-8;
  font-weight: 700;
  color: $ue-black;
  &:focus,
  &:hover {
    color: $ue-black;
  }
  @each $category, $color in $category-colors {
    &_#{$category} {
      &:focus,
      &:hover {
        color: $color;
      }
    }
  }
}
