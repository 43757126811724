@import '../../../scss/utilities';

.articles {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.articles__item {
  padding-top: $grid-gutter-width*0.5;
  padding-bottom: $grid-gutter-width*0.5;
  &:first-child {
    padding-top: 0;
    padding-bottom: $grid-gutter-width;
  }
  &_card {
    border-bottom: $ue-dashed-border;
    &:last-child {
      border-bottom: none;
    }
  }
  &_latest {
    &:first-child {
      border-bottom: $ue-dashed-border;
    }
  }
}

.articles__link {
  color: $ue-black;
  &:hover,
  &:focus {
    color: $ue-black;
  }
}

.articles__featured {
  font-family: $ue-header-font;
  font-weight: 700;
  font-size: $ue-font-size-7;
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: $ue-font-size-6;
  }
}

.articles__secondary {
  font-family: $ue-subheader-font;
}
